import {
  Event,
  EventCategory,
  EventMeta,
  EventSport,
  EventTournament,
  EventType,
} from '@scpc/modules/sports/dto';
import { Observable, of } from 'rxjs';

export function isEventEnded(event?: Event): boolean {
  if (!event) {
    return false;
  }
  return event.status === 'Ended' || event.status === 'Finished';
}

export function createEventMeta(event: Event): EventMeta {
  return {
    id: event.id,
    scheduleTime: event.scheduleTime,
    teamName: event.homeTeam?.name || event.season.name,
    season: !!event.season,
  };
}

export function toObservable<T>(items: T[], fun: () => Observable<T[]>): Observable<T[]> {
  return items?.length ? of(items) : fun();
}

export function getTournamentSchema(data: {
                                      category: EventCategory,
                                      tournament: EventTournament,
                                      sport: EventSport
                                    },
                                    type: EventType,
                                    host: string): object | null {
  const name: string = `${data.sport.name}. ${data.category.name}. ${data.tournament.name}`;
  const site: string = host.split('//')[1];

  return {
    '@context': 'https://schema.org',
    '@type': 'EventSeries',
    'name': name,
    'description': `Bets on ${name} at ${site} online`,
    'image': getTournamentImage(data.tournament.id, host),
    'startDate': '2023-05-02',
    'eventStatus': 'https://schema.org/EventScheduled',
    'eventAttendanceMode': 'https://schema.org/OnlineEventAttendanceMode',
    'location': {
      '@type': 'VirtualLocation',
      'url': `${host}/sports/${type}/${data.sport.slug}/${data.tournament.slug}`,
    },
    'performer': {
      '@type': 'PerformingGroup',
      'name': 'YesPlay.bet',
    },
  };

}

function getTournamentImage(id: string, host: string): string {
  return `${host}/assets/common/sports/tournaments/${id.replace(/:/g, '-')}.png`;
}
